import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _forEach3 from "lodash/forEach";

var _forEach = "default" in _forEach3 ? _forEach3.default : _forEach3;

var exports = {};
var _interopRequireDefault = _interopRequireDefault2.default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = cleanNode;

var _forEach2 = _interopRequireDefault(_forEach);

function cleanNode(node) {
  delete node.parent; // Delete children if needed

  if (node.children && node.children.length) {
    (0, _forEach2.default)(node.children, cleanNode);
  } else {
    delete node.children;
  } // Delete attributes if needed


  if (node.attributes && Object.keys(node.attributes).length === 0) {
    delete node.attributes;
  }
}

exports = exports.default;
export default exports;