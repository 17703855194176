import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _forEach3 from "lodash/forEach";

var _forEach = "default" in _forEach3 ? _forEach3.default : _forEach3;

var exports = {};
var _interopRequireDefault = _interopRequireDefault2.default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = setEmptyAttributes;

var _forEach2 = _interopRequireDefault(_forEach);

function setEmptyAttributes(node) {
  if (!node.attributes) {
    node.attributes = {};
  }

  if (node.children) {
    (0, _forEach2.default)(node.children, setEmptyAttributes);
  }
}

exports = exports.default;
export default exports;